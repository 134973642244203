import dayjs from 'dayjs';
import {
    MSG_GENERIC_ERROR,
    REQUIRED_FORM_FIELD_TEXT,
} from '../config/constants';
import xhrError from './xhr';

const randomNumber = (min = 0, max = 1) => {
    return Math.random() * (max - min) + min;
};

export default randomNumber;

export const formatPhoneNumber = (str) => {
    let formattedNumber = '';

    if (!str) return formattedNumber;

    const x = str.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    formattedNumber = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

    return formattedNumber;
};

export const getQueryStringParams = (query) => {
    if (!query || typeof query !== 'string') return {};

    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
              .split('&')
              .reduce((params, param) => {
                  let [key, value] = param.split('=');
                  params[key] = value
                      ? decodeURIComponent(value.replace(/\+/g, ' '))
                      : '';
                  return params;
              }, {})
        : {};
};

export const validatePhoneNumber = (value) => {
    if (!value) return false;

    return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value);
};

export const getFirstInvalidFormElement = (frm) => {
    if (!frm) return;

    try {
        const invalidFields = Object.keys(frm).filter(
            (fld) => frm[fld].$invalid,
        );

        // console.log('invalidFields', invalidFields);

        if (
            invalidFields &&
            Array.isArray(invalidFields) &&
            invalidFields.length
        ) {
            return invalidFields[0];
        }
    } catch (e) {
        //
    }

    return null;
};

export const focusOnFirstInvalidFormElement = (frm, displayAlert = false) => {
    const elementName = getFirstInvalidFormElement(frm);

    if (elementName) {
        const elmArr = document.getElementsByName(elementName);

        if (elmArr && elmArr.length) elmArr[0].focus();

        if (displayAlert && window && window.toastr) {
            window.toastr.error(REQUIRED_FORM_FIELD_TEXT);
        }
    }
};

export const isAValidNumber = (value) => {
    if (!value) return true;

    return !isNaN(value);
};

export const formatDate = (dateStringOrNumber, formatString = 'MM-DD-YYYY') => {
    if (dateStringOrNumber) {
        try {
            if (!isNaN(dateStringOrNumber)) {
                return dayjs.unix(dateStringOrNumber).format(formatString);
            } else {
                return dayjs(dateStringOrNumber).format(formatString);
            }
        } catch (e) {
            return dateStringOrNumber;
        }
    }

    return null;
};

export const selectFormOptionFields = (
    fieldNamesArr,
    fieldIndex,
    fieldPrefix = null,
    // fieldIdSuffix = null,
    fieldIdSeparator = null,
    itemsCount = null,
) => {
    const prefixFieldsArr = fieldNamesArr || null;
    const fieldsToUpdateArr = [];

    if (itemsCount) {
        for (let i = 0; i < itemsCount; i += 1) {
            let formFieldId = '';

            if (fieldPrefix) formFieldId += fieldPrefix;
            if (fieldIdSeparator) formFieldId += fieldIdSeparator;

            formFieldId += `${i}${fieldIdSeparator}${fieldIndex}`;

            const elm = document.getElementById(formFieldId);

            if (elm) elm.click();
        }
    } else {
        if (
            prefixFieldsArr &&
            Array.isArray(prefixFieldsArr) &&
            prefixFieldsArr.length
        ) {
            prefixFieldsArr.map((itm) => {
                let fld = itm;

                if (Array.isArray(fld)) {
                    fld = fld[0];
                }

                fieldsToUpdateArr.push(fld);
            });
        }

        fieldsToUpdateArr.map((fld) => {
            let formFieldId = fld;

            if (fieldPrefix) {
                if (fieldIdSeparator) formFieldId += `${fieldIdSeparator}`;

                formFieldId += `${fieldPrefix}`;
            }

            if (fieldIndex) formFieldId += `${fieldIdSeparator}${fieldIndex}`;

            const elm = document.getElementById(formFieldId);

            if (elm) elm.click();
        });
    }
};

export const isMobile = () => {
    return window.matchMedia('only screen and (max-width: 768px)').matches;
};

export const sortObjectArray = (key, order = 'asc') => {
    return function (a, b) {
        if (!a || !b || typeof a !== 'object' || typeof b !== 'object')
            return 0;

        if (!Object.hasOwn(a, key) || !Object.hasOwn(b, key)) {
            // property doesn't exist on either object
            return 0;
        }

        const valA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
        const valB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

        let sort = 0;

        if (valA > valB) {
            sort = 1;
        } else if (valA < valB) {
            sort = -1;
        }

        return order === 'desc' ? sort * -1 : sort;
    };
};

export const lookupPhoneNumber = async (
    ctx,
    stateKey,
    val,
    ignoreValidation = false,
) => {
    let stateKeyObj = {
        message: null,
        busy: false,
        valid: null,
    };

    // fail fast
    if (
        !val ||
        !validatePhoneNumber(val) ||
        !ctx ||
        !ctx.phoneNumberLookupRoute
    ) {
        return;
    }

    stateKeyObj.busy = true;

    ctx.state[`${stateKey}`] = {
        ...stateKeyObj,
    };

    await window.axios
        .post(ctx.phoneNumberLookupRoute, {
            phone_number: val,
        })
        .then((result) => {
            const lookup = { ...stateKeyObj };

            if (
                result.data &&
                (ignoreValidation ||
                    ['mobile', 'voip'].includes(result.data.type))
            ) {
                stateKeyObj = {
                    ...lookup,
                    message: null,
                    valid: true,
                };
            } else {
                let msg =
                    'The supplied phone number is :number_text_string not a valid mobile number';

                if (result.data.type) {
                    msg = msg.replace(
                        ':number_text_string',
                        'a ' + result.data.type + ' and ',
                    );
                } else {
                    msg = msg.replace(':number_text_string', '');
                }

                stateKeyObj = {
                    ...lookup,
                    message: msg,
                    valid: false,
                };
            }
        })
        .catch((err) => {
            const errBag = xhrError(err);
            const errMsg = errBag ? errBag.message : MSG_GENERIC_ERROR;

            if (errMsg) {
                stateKeyObj.message = errMsg;
            }

            stateKeyObj.valid = false;
        })
        .finally(() => {
            stateKeyObj.busy = false;
        });

    ctx.state[`${stateKey}`] = { ...stateKeyObj };
};

export const formatInputToDateString = (
    str,
    separator = '-',
    format = 'MM-DD-YYYY',
) => {
    let formattedDate = '';

    if (!str) return formattedDate;

    let x;

    const dateFormatWithoutSeparator = format
        .replaceAll(separator, '')
        .toUpperCase();

    if (['MMDDYYYY', 'DDMMYYYY'].includes(dateFormatWithoutSeparator)) {
        x = str.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
    } else if (dateFormatWithoutSeparator === 'YYYYMMDD') {
        x = str.replace(/\D/g, '').match(/(\d{0,4})(\d{0,2})(\d{0,2})/);
    } else {
        x = str.replace(/\D/g, '').match(/(\d{0,4})(\d{0,2})(\d{0,2})/);
    }

    if (x) {
        formattedDate = !x[2]
            ? x[1]
            : x[1] + separator + x[2] + (x[3] ? separator + x[3] : '');
    }
    return formattedDate;
};

export const datepickerOptions = {
    labelHelp: 'Use cursor keys to navigate calendar dates',
    labelNoDateSelected: 'No date selected',
    dateFormatOptions: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    },
    locale: 'en-US',
};

export const goBack = () => {
    if (!window || !window.history || window.history.length <= 1) {
        return;
    }

    window.history.back();
};

export const yesNoSelectOptions = [
    { value: '', text: '-' },
    { value: 1, text: 'Yes' },
    { value: 0, text: 'No' },
];
